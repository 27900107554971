<template>
    <a-config-provider :locale="zh_CN" id="report-main">
        <a-spin :spinning="loading">
            <a-card>
                <div class="my-md">
                    <a-row>
                        <a-col :span="24">
                            <a-date-picker mode="year" :allowClear="false" format="YYYY" :value="currentYear"
                                         id="picker"  @openChange="isOpen = true" :open="isOpen" @panelChange="yearChange"/>
                            <a-divider type="vertical" v-if="isTenant"></a-divider>
                            <a-tree-select
                                    @change="companyChange"
                                    v-if="isTenant"
                                    :placeholder="l('Company')"
                                    style="width: 200px"
                                    allowClear
                                    tree-default-expand-all
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="companyTreeList"
                                    v-model="companyId"
                            />
                            <a-divider type="vertical" v-if="isHost"></a-divider>
                            <a-select
                                    v-if="isHost"
                                    style="width: 200px"
                                    @change="getData"
                                    allowClear
                                    v-model="tenantId"
                                    placeholder="租户">
                                <a-select-option  v-for="item in tenantList" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </div>
                <div class="my-md" >
                    <div style="width: 100%;height: 400px">
                        <a-row>
                            <a-col :span="12">
                                <div style="width: 100%;height: 400px" ref="report1">

                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div style="width: 100%;height: 400px" ref="report2">

                                </div>
                            </a-col>
                        </a-row>
                    </div>
                    <div style="width: 100%;height: 400px" ref="report3">

                    </div>
                </div>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../../shared/component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {CompanyServiceProxy, FeedbackServiceProxy, TenantServiceProxy} from "../../../../shared/service-proxies";
    import * as echarts from 'echarts'
    import moment from 'moment'

    export default {
        name: "month-submit-off-report",
        mixins: [AppComponentBase],
        data() {
            return {
                zh_CN,
                //当前年
                currentYear: moment(),
                //是否打开年选择
                isOpen: false,
                //公司ID
                companyId: undefined,
                //公司树列表
                companyTreeList: [],
                //公司接口列表
                companyList: [],
                eChart1:undefined,
                eChart2:undefined,
                eChart3:undefined,
                tenantId:undefined,
                tenantList:[]
            }
        },
        computed:{
            isHost(){
                return  abp.session.multiTenancySide==='Host';
            },
            isTenant(){
                return  abp.session.multiTenancySide==='Tenant';
            }
        },
        watch:{

        },
        created() {
            this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
            this._feedbackServiceProxy = new FeedbackServiceProxy(this.$apiUrl, this.$api);
            this._tenantService = new TenantServiceProxy(this.$apiUrl, this.$api);
            // window.addEventListener("click", e => {
            //     let list = document.getElementById("picker");
            //     if (!list.contains(e.target)) {
            //         this.isOpen=false;
            //     } 
            //
            // });
        },
        async mounted() {
            this.companyTreeInit();
            if(abp.session.multiTenancySide==='Host'){
                this.tenantList= await this.getTenantList();
            }
            this.eChart1=echarts.init(this.$refs.report1);
            this.eChart2=echarts.init(this.$refs.report2);
            this.eChart3=echarts.init(this.$refs.report3);
            this.getData();
        },
        methods: {
            getTenantList(){
                return new Promise(resolve => {
                    this._tenantService.getTenantList()
                        .then(res=>{
                            resolve(res);
                        })
                })
            },
            getData() {
                try {
                    this.loading = true;
                    this.getMonthSubmitOffReport();
                    this.getYearOffReport();
                    this.getFeedbackTypeGroupReport();
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading = false;
                }

            },
            /**
             * 月度提交与关闭量
             */
            getMonthSubmitOffReport(){
                this._feedbackServiceProxy.getMonthSubmitOffReport(this.currentYear.year(), this.companyId,this.tenantId)
                    .then(res => {
                        let option = {
                            title: {
                                text: '月度提交与关闭',
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            color:['#FFA07A','#91CC75'],
                            legend: {
                                data: ['提交量','关闭量']
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    axisTick: {show: false},
                                    data: ['1月', '2月', '3月', '4月','5月','6月','7月','8月','9月','10月','11月','12月']
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            series: [
                                {
                                    name: '提交量',
                                    type: 'bar',
                                    barGap: 0,
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: res.submitData
                                },
                                {
                                    name: '关闭量',
                                    type: 'bar',
                                    barGap: 0,
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: res.offData
                                }
                            ]
                        };
                        this.eChart3.setOption(option);
                        window.addEventListener('resize', () => {
                            this.eChart3.resize();
                        });
                    })
            },
            /**
             *年度关闭量
             */
            getYearOffReport(){
                this._feedbackServiceProxy.getYearOffReport(this.currentYear.year(), this.companyId,this.tenantId)
                .then(res=>{
                    let option = {
                        title: {
                            text: '年度关闭量',
                            left: 'center'
                        },
                        color:['#FFA07A','#91CC75'],
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b} : {c} ({d}%)'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                        },
                        series: [
                            {
                                name: '意见反馈',
                                type: 'pie',
                                radius: '50%',
                                data: res,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    };
                    // const eChart = echarts.init(this.$refs.report1);
                    this.eChart1.setOption(option);
                    window.addEventListener('resize', () => {
                        this.eChart1.resize();
                    });
                })
            },
            /**
             *年度问题分类占比
             */
            getFeedbackTypeGroupReport(){
                this._feedbackServiceProxy.getFeedbackTypeGroupReport(this.currentYear.year(), this.companyId,this.tenantId)
                .then(res=>{
                    let option = {
                        title: {
                            text: '年度问题分类占比',
                            left: 'center'
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b} : {c} ({d}%)'
                        },
                        // color:['#FFA500','#32CD32','#FFA07A','#FAEBD7'],
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                        },
                        series: [
                            {
                                name: '意见分类',
                                type: 'pie',
                                radius: '50%',
                                data: res,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    };
                    this.eChart2.setOption(option);
                    window.addEventListener('resize', () => {
                        this.eChart2.resize();
                    });
                })
            },
            //年选择器改变
            yearChange(e) {
                this.isOpen = false;
                this.currentYear = e;
                this.getData();
            },
            companyTreeInit() {
                this.loading = true;
                this._CompanyConfigServiceProxy
                    .getPaged(undefined, "", 100, 0)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName
                                };
                                this.companyTreeList.push(obj);

                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                    });
            },
            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            companyChange(value) {
                this.companyId = value;

                this.getData();
            },
        }
    }
</script>

<style scoped>

</style>
